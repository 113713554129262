<template>
    <tr>
        <td>
            <span class="type px-1" :class="item.types[0]">{{ item.types.join('-') }}</span>
        </td>
        <td>periodico</td>
        <td>{{ configuracion }}</td>
        <td>{{ ts }}</td>
        <td>{{ te }}</td>
        <td class="text-center">
            <button class="btn btn-sm btn-primary px-1 py-0" @click="handleEdit">Editar</button>
            <button class="btn btn-sm btn-danger px-1 py-0 ms-1" @click="handleRemove">
                Borrar
            </button>
        </td>
    </tr>
</template>
<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';

export default {
    name: 'EventTableRow',
    components: {},
    props: {
        item: { type: Object, required: true },
    },
    setup(props, { emit }) {
        const configuracion = computed(() => {
            const days = props.item.schedule.map((s) => {
                const date = DateTime.now().set({ weekday: s.weekDay });
                return date.toFormat('EEEE', { locale: 'co' });
            });

            return days.join(', ');
        });

        const ts = computed(() => {
            const dts = DateTime.now().set({
                hour: props.item.schedule[0].startHour,
                minute: props.item.schedule[0].startMin,
            });

            return dts.toFormat('hh:mm a', {
                locale: 'co',
            });
        });

        const te = computed(() => {
            const dte = DateTime.now().set({
                hour: props.item.schedule[0].endHour,
                minute: props.item.schedule[0].endMin,
            });

            return dte.toFormat('hh:mm a', {
                locale: 'co',
            });
        });

        const handleEdit = () => {
            emit('onEdit', props.item);
        };

        const handleRemove = () => {
            emit('onRemove', props.item.id);
        };

        return {
            configuracion,
            ts,
            te,
            handleEdit,
            handleRemove,
        };
    },
};
</script>
<style scoped>
.consulta {
    background-color: #3d79d5;
    color: white;
    padding-block: 1px;
}
.procedimiento {
    background-color: #39c173;
    color: white;
    padding-block: 1px;
}
</style>
